const styles = theme => ({
  ...theme.classes,
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 32px',
    height: 76,
    '@media (max-width:1240px)': {
      height: 60,
      padding: '0 30px',
      borderBottom: '1px solid #E4E5E7',
    },
    '@media (max-width:370px)': {
      padding: '0 10px 0 20px',
    },
  },
  headContainer: {
    minHeight: 30,
    alignItems: 'center',
  },
  h1: {
    fontSize: 16,
    lineHeight: '46px',
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
    '@media (max-width:1240px)': {
      lineHeight: '22px',
      color: '#2C3033',
    },
  },
  btn: {
    fontSize: 14,
    minHeight: 30,
    minWidth: 98,
    marginRight: 14,
    padding: '0 20px',
    textTransform: 'capitalize',
    borderRadius: 100,
    boxShadow: 'none',
    border: '0 none',
    color: '#fff',
  },
  btnhold: {
    backgroundColor: '#E3C000',
    margin: 0,
    '&:hover': {
      backgroundColor: '#E3C000',
      color: '#fff',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
    '@media (max-width:1240px)': {
      minWidth: 64,
      minHeight: 24,
    },
  },
  btncreate: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },
    '@media (max-width:1240px)': {
      minWidth: 85,
      fontSize: 12,
      padding: '0 14px',
      minHeight: 24,
      marginRight: 0,
    },
  },
  gridStyle: {
    padding: 0,
  },
  gridleft: {
    '@media (max-width:1240px)': {
      maxWidth: '45%',
      flexBasis: '45%',
      '&[data-lbl="Return Order Details"]': {
        maxWidth: '80%',
        flexBasis: '80%',
      },
      '&.grleft': {
        lineHeight: '30px',
        maxWidth: '50%',
        flexBasis: '50%',
      },
    },
  },
  gridright: {
    textAlign: 'right',
    lineHeight: '30px',
    '@media (max-width:1240px)': {
      maxWidth: '55%',
      flexBasis: '55%',
      '&[data-lbl="Return Order Details"]': {
        maxWidth: '20%',
        flexBasis: '20%',
      },
      '&.grright': {
        maxWidth: '50%',
        flexBasis: '50%',
      },
    },
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: theme.palette.secondary.main,
    margin: '0 8px',
    cursor: 'pointer',
    '@media (max-width:1240px)': {
      padding: '0 0 0 12px !important',
    },
    '@media (max-width:370px)': {
      padding: '0 0 0 8px !important',
    },
  },
  submitWrapper: {
    position: 'relative',
    display: 'inline-block',
    margin: '0 0 0 18px',
    '@media (max-width:370px)': {
      margin: '0 0 0 12px',
    },
  },
  modalBtn2: {
    backgroundColor: '#0CD581',
    height: '26px !important',
    minHeight: '26px !important',
    margin: 0,
    '&:hover': {
      backgroundColor: '#0CD581',
    },
    '& span': {
      color: '#fff !important',
      fontWeight: '500 !important',
      fontSize: '13px !important',
    },
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  rotating: {
    animation: 'rotating 1s linear infinite',
  },
  syncbtn: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  cancelbtn2: {
    '@media (max-width:1240px)': {
      minHeight: '24px !important',
      padding: '0 12px !important',
      height: '24px !important',
      lineHeight: '22px',
    },
  },
});

export default styles;
